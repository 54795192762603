import React from "react";
import Navbar, {Nav} from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";


const menuContent = [
    {name: "Home", scrollTo: "#"},
    {name: "About us", scrollTo: "#about"},
    {name: "Services", scrollTo: "#services"},
    {name: "Projects", scrollTo: "#projects"},
    {name: "Clients", scrollTo: "#clients"},
    {name: "Contact us", scrollTo: "#contact"},
];

const DroowMenu = ({hamburger}) => {

    return (
        <Navbar hamburger={hamburger}>
            <Navbar.Brand to={"/"}>
                <Brand width="50px" height="50px"/>
            </Navbar.Brand>

            <Navbar.Collapse cover="Menu">
                <Nav>
                    {menuContent.map((item, i) => <Nav.Link href={item.scrollTo} key={i}>{item.name}</Nav.Link>)}
                </Nav>
                <MenuContent className="section-margin"/>
            </Navbar.Collapse>
        </Navbar>
    );

}

DroowMenu.defaultProps = {
    hamburger: false
}


export default DroowMenu;